import { Colours } from '../Constants/Colours';
import { useState, useEffect } from "react";
import { HeaderProperties } from "../Constants/HeaderProperties";
import styled from "styled-components";
import { Ease } from "../Constants/EasingCurves";
import { ResponsiveBreakpoints } from "../Constants/ResponsiveBreakpoints";

const
    CookieWrapper = styled.div`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(90rem/16);
        z-index: 26;
        background: #222;
        color: white;
        @media screen and (min-width: calc(186em/16)) {
            height: calc(70rem/16);
        }

        @media screen and (min-width: calc(360em/16)) {
            height: calc(55rem/16);
        }

        &.is-hidden {
            top: -100%;
            opacity: 0;

        }

        .CookieConsent {
            display: flex !important;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            padding: 0;
            min-height: ${ HeaderProperties.height };
            @media screen and (min-width: calc(700em/16)) {
                flex-direction: row;
                justify-content: center;
            }
            > div {
                margin: 0 auto !important;
                flex: unset !important;
                &:first-child {
                    margin: 0 !important;
                    padding: 0.75rem !important;
                    text-align: center;
                }

                &:last-child {
                    width: calc(165rem/16) !important;
                }
            }

            span {
                font-size: calc(12rem/16);
                text-align: center;
                @media screen and (min-width: calc(700em/16)) {
                    font-size: calc(16rem/16);
                }
            }
        }

        button {
            width: auto;
            color: white !important;
            box-shadow: 0 0 0 calc(2rem/16) ${ Colours.IckonicPink } !important;
            background: ${ Colours.IckonicPink } !important;
            transition: background .25s ${ Ease.Smooth }, color .25s ${ Ease.Smooth };
            font-weight: bold;
            font-size: calc(12rem/16);
            border-radius: calc(15rem/16) !important;
            border: none;
            cursor: pointer;
            display: inline-block;
            padding: calc(2rem/16) calc(15rem/16) !important;
            margin: 0 auto !important;
            &:hover,
            &:focus {
                background: none !important;
            }

            @media screen and (min-width: calc(700em/16)) {
                margin: 0 0 0 calc(20rem/16) !important;
                padding: calc(5rem/16) calc(30rem/16) !important;
                font-size: calc(16rem/16);
            }
        }

        @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }rem/16)) {
            .CookieConsent {
                display: flex !important;
                flex-wrap: nowrap !important;
                justify-content: center !important;
                align-items: center !important;
                padding: 0;
                > div {
                    &:first-child {
                        margin: 0 !important;
                    }

                    &:last-child {
                        margin: 0 0 0 calc(8rem/16) !important;
                    }
                }
            }
        }
    `;

function AcceptCookies() {
    const
        [ isHidden, setIsHidden ] = useState<boolean>(false),

        gtag = (...args: any[]) => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(...args);
        },

        isLocalStorageAvailable = () => {
            try {
                const testKey = "__testKey";
                localStorage.setItem(testKey, testKey);
                localStorage.removeItem(testKey);
                return true;
            } catch (e) {
                return false;
            }
        },

        provideConsent = () => {
            if (typeof window !== "undefined" && isLocalStorageAvailable()) {

                const newConsent = {
                    'analytics_storage': 'granted'
                };

                // Send the consent data to GTM
                gtag('consent', 'update', newConsent);

                // Store the consent data in a cookie
                localStorage.setItem("consentMode", JSON.stringify(newConsent));
            }

            window.location.reload();
        };

    useEffect(() => {
        if (typeof window !== "undefined" && isLocalStorageAvailable()) {
            // Retrieve the item from localStorage
            const consentAlreadyProvided = localStorage.getItem('consentMode') !== null;

            // Check if the item exists and is not null
            if (consentAlreadyProvided) {
                setIsHidden(true);
            }
        }
    }, []);

    return (
        <CookieWrapper className={ isHidden ? "is-hidden" : "is-visible" }>
            <div className="CookieConsent">
                <span>
                    This website uses cookies to enhance the user experience.
                </span>

                {/** Updates Consent Mode to allow Google Tag Manager **/}
                {/** To start tracking data. **/}
                <button onClick={ () => provideConsent() }>
                    I understand
                </button>
            </div>
        </CookieWrapper>
    );
}

export default AcceptCookies;
