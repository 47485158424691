import { NavLink, useNavigate } from "react-router-dom";
import { Colours } from "../../../../Constants/Colours";
import { useState } from "react";
import { useContext } from "react";
import { ISearchFilterDTO } from "../../../../Models/DTOs/ISearchFilterDTO";
import styled from "styled-components";
import { IckonicLogo, IckonicLogoIcon } from '../../../../Assets/Images/Logos/Logos';
import HamburgerMenu from "./HamburgerMenu";
import AuthContext from "../../../../Store/auth-context";
import { RoutePaths } from "../../../../Constants/RoutePaths";
import { IsAuthenticated } from "../../../../Helpers/UserUtility";
import {WatchSecondary} from "../../../../Constants/SecondaryNavBars";
import { HeaderProperties } from "../../../../Constants/HeaderProperties";
import {ExternalLink, IconProfileThin, IconQuestionThin} from "../../../../Assets/Icons/Icons";
import SearchBar from "../SearchBar";
import { Fonts } from "../../../../Constants/Fonts";
import { Ease } from "../../../../Constants/EasingCurves";
import NavLinkPinkButton from "../../Buttons/NavLinkPinkButton";
import {ResponsiveBreakpoints} from "../../../../Constants/ResponsiveBreakpoints";

const Header = styled.header`
    background: ${ Colours.SecondaryDarker };
    height: ${ HeaderProperties.height };
    flex: 0 1 auto;
    z-index: 25;
    position:fixed;
    top: 0;
    width:100%;
    .link--subscribe {
        position: absolute;
        top: ${ HeaderProperties.height };
        width: 100%;
        height: calc(12rem/16);
        color: white;
        background: ${ Colours.IckonicPink };
        border-radius: 0;
        text-transform: uppercase;
        font-size: 12pt;
        z-index: 3;
        &:hover,
        &:focus {
            color: black;
            background: ${ Colours.Accent };
            box-shadow: inset 0 0 0 calc(2rem/16) ${Colours.Accent};
        }
        
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.MediumMobileBreakpoint }em/16)) {
            top: calc(12.5rem/16);
            height: calc(30rem/16);
            left: calc(120rem/16);
            width: calc(120rem/16);
            border-radius: calc(3rem/16);
            color: black;
            background: ${ Colours.Accent };
            box-shadow: inset 0 0 0 calc(2rem/16) ${Colours.Accent};
            animation-name: pulseSoftAccent;
            animation-iteration-count: infinite;
            animation-duration: 3s;
            animation-timing-function: ${ Ease.Smooth };  
            &:hover,
            &:focus {
                color: white;
            }
        }
    }
`;

const Navigation = styled.nav`
  display: flex ;
`;

const ListRight = styled.ul`
    height: ${ HeaderProperties.height };
    width: calc(100% - 60px);
    list-style: none;
    display: inline-flex;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0 0 0 auto;
    align-items: center;
    justify-content: flex-end;
    @media screen and (min-width: calc(350rem/16)) {
        width: calc(100% - 117px);
    }
`;

const StyledLogo = styled(NavLink)`
    display: flex;
    position: absolute;
    top: 4px;
    left: 4px;
    height: 47px;
    width: 47px;
    padding: 8px;
    svg {
        * {
            fill: #ffffff;
        }
        
        &.ickonic-logo--primary {
            display: none;
        }
    }
    
    @media screen and (min-width: calc(350rem/16)) {
        width: 108px;
        padding: 10px;
        left: 5px;
        svg {

            &.ickonic-logo--primary {
                display: inherit;
            }
            
            &.ickonic-logo--icon {
                display: none;
            }
        }
    }
`;

const HamburgerInteractionElement = styled.button`
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: none;
    cursor: pointer;
    padding: 0;
    font-size: 0;
    z-index: 2;
    text-indent: -9999px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    transition: background .27s, opacity .27s ${ Ease.LateSnap }, transform .27s ${ Ease.LateSnap } .3s;
    opacity: 1;
    margin: 0 0 0 calc(3rem/16);
    position: absolute;
    display: table;
    overflow: visible;
    &.js-desktop {
        display: none;
    }

    span {
        display: block;
        position: absolute;
        top: 50%;
        right: calc(50% - (2rem/16));
        width: calc(20rem/16);
        height: calc(0rem/16);
        &:before,
        &:after {
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: calc(2rem/16);
            background: white;
            content: "";
        }

        &:before {
            top: calc(3rem/16);
            transition: transform .27s ${ Ease.LateSnap }, background .27s ${ Ease.LateSnap }, top .27s ${ Ease.LateSnap };
        }

        &:after {
            bottom: calc(3rem/16);
            transition: transform .27s ${ Ease.LateSnap }, background .27s ${ Ease.LateSnap }, bottom .27s ${ Ease.LateSnap };
        }
    }

    &:focus,
    &:hover {
        outline: none;
        border: none;
        background: none;
        box-shadow: none;
    }

    &.is-active {
        background: none;
        span {
            &:before,
            &:after {
                background: white;
            }

            &:before {
                top: calc(-1rem/16);
                transform: rotate(45deg);
            }

            &:after {
                bottom: calc(-1rem/16);
                transform: rotate(-45deg);
            }
        }
    }
`;

const StyledLink = styled(NavLink)`
    color: ${Colours.Text};
    text-decoration: none;
    font-family: ${ Fonts.Primary };
    font-size: 12pt;
    padding-bottom: 0.25rem;
    position:relative;
    padding: 12px 13px 12px 10px;
    display: block;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    border-bottom: calc(1rem/16) solid rgba(200,200,200,0.075);
    transition-duration: 200ms;
    transition-timing-function: ${ Ease.Smooth };
   
   
    &:hover,
    &:focus,
    &:active {
        background: rgba(200,200,200,0.075);
        box-shadow: 0 0 0 0 rgba(200,200,200,0.075);
        padding: 12px 10px 12px 13px;
    }
    

`;

const MenuIcon = styled.div`
    width: 20px;
    height: 20px;
    transform: translateY(3px);
    margin: 0 10px 0 0;
    display: inline-block;
    svg {
        * {
            fill: white;
        }
    }
`;

const MenuLi = styled.li`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    svg {
        width: calc(10rem/16);
        height: calc(10rem/16);
        margin: 0 0 0 calc(5rem/16);
        * {
            fill: ${Colours.Text};
        }
    }
    
    a {
        width: 100%;
        &:hover.
        &:focus {
            svg {
                * {
                    fill: white;
                }
            }
        }
    }
    
    &:last-of-type {
        a {
        border-bottom: none;
        }
    }
`;

const MenuLiButton = styled.li`
    width: calc(50% - 6px);
    margin: 0 0 16px 0;
`;

const MenuUl = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    list-style: none;
    margin: 0px;
    padding: 14px;
`;

const ctaButtonBaseStyle = `
    color: ${Colours.Text};
    font-size: 10pt;
    font-family: ${ Fonts.Primary };
    text-decoration: none;
    cursor: pointer;
    padding: 6px 15px;
    line-height: 1;
    font-weight: 600;
    border: 0;
    color: white;
    font-size: 11pt;
    text-align: center;
    border-radius: 15px;
    display: block;
    transition: background .25s ${ Ease.Smooth }, color .25s ${ Ease.Smooth }, box-shadow .25s ${ Ease.Smooth };
`;

const MenuA = styled.a`
    ${ ctaButtonBaseStyle }
    box-shadow: 0 0 0 calc(2rem/16) #444444;
    margin: 10px 0 0 0;
    &:hover,
    &:focus,
    &:active {
        background: white;
        color: black;
    }
`;

const StyledLogin = styled(NavLink)`
    ${ ctaButtonBaseStyle }
    box-shadow: 0 0 0 calc(2rem/16) #333333;
    line-height: 1.3;
    &:hover,
    &:focus,
    &:active {
        box-shadow: 0 0 0 calc(2rem/16) #ffffff;
    }
`;

const StyledRegister = styled(NavLink)`
    ${ ctaButtonBaseStyle }
    box-shadow: 0 0 0 calc(2rem/16) ${ Colours.IckonicPinkHighlight };
    line-height: 1.3;
    &:hover,
    &:focus,
    &:active {
        background: ${ Colours.IckonicPinkHighlight };
        color: black;
    }
`;

const StyledRegisterMainCTA = styled(NavLink)`
    ${ ctaButtonBaseStyle }
    padding: 0;
    color: ${ Colours.IckonicPinkHighlight };
    margin: 0 15px 0 0;
    border-radius: 18px;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    svg {
        width: 16px;
        height: 16px;
        transform: translateY(-1px);
        margin: 0 8px 0 0;
        * {
            transition: fill .25s ${ Ease.Smooth }
        }
    }
  
    
    svg {
        display: none;
    }
    
    @media screen and (min-width: calc(300rem/16)) {
        margin: 0 15px 0 0;
        padding: 6px 15px;
        font-weight: bold;
      
        color: white;
        box-shadow: 0 0 0 calc(2rem/16) ${ Colours.IckonicPinkHighlight };
        &:hover,
        &:focus,
        &:active {
            background: ${ Colours.IckonicPinkHighlight };
            color: black;
                svg {
                    * {
                        fill: black;
                    }
                }
        }
    }
`;

function NavBar(props: {
    searchText: string,
    onSearchUpdate: (text: string) => void,
    searchFilter: ISearchFilterDTO,
    onSearchFilterUpdate: (filters: ISearchFilterDTO) => void,
    isAuthenticated: boolean,
    currentCountryCode: string,
}) {

    const authCtx = useContext(AuthContext);
    const [menuActive, setMenuActive] = useState<boolean>(false);
    const navigate = useNavigate();

    function OnActiveToggle(value: boolean) {
        setMenuActive(value);
    }

    async function OnLogout() {
        await authCtx.LogOut();
        navigate(RoutePaths.Root);
    }

    return (
        <Header>
            <Navigation>
                <StyledLogo to={props.isAuthenticated ? RoutePaths.Browse : "/"}>
                    <IckonicLogo />
                    <IckonicLogoIcon />
                </StyledLogo>

                { props.isAuthenticated && authCtx.UserDetails.SubscriptionStatus === 0 ?
                    (
                        <NavLinkPinkButton to={ RoutePaths.ChoosePlan } className="link--subscribe">
                            Subscribe
                        </NavLinkPinkButton>
                    )
                    :
                    null
                }

                <ListRight>
                    { !props.isAuthenticated && (
                        <StyledRegisterMainCTA
                            onClick={() =>
                                OnActiveToggle(false)
                            }
                            to={RoutePaths.CreateYourAccount}
                        >
                            Watch Now
                        </StyledRegisterMainCTA>
                    ) }

                    { IsAuthenticated(authCtx.UserDetails) ? (
                        <SearchBar
                            searchText={props.searchText}
                            onSearchUpdate={props.onSearchUpdate}
                            searchFilter={props.searchFilter}
                            onSearchFilterUpdate={props.onSearchFilterUpdate}
                            toggleMobileMenuClosed={ () => OnActiveToggle(false) }
                            currentCountryCode={ props.currentCountryCode }
                        />
                    ) : null }

                    <HamburgerMenu
                        header={
                            <HamburgerInteractionElement
                                className={ menuActive ? 'is-active' : 'is-not-active' }
                            ><span> </span></HamburgerInteractionElement>
                        }
                        isActive={menuActive}
                        setIsActive={OnActiveToggle}
                    >
                        <MenuUl>
                            { !props.isAuthenticated && (
                                <>
                                    <MenuLiButton>
                                        <StyledLogin
                                            onClick={() =>
                                                OnActiveToggle(false)
                                            }
                                            to={RoutePaths.Login()}
                                        >
                                            Log in
                                        </StyledLogin>
                                    </MenuLiButton>

                                    <MenuLiButton>
                                        <StyledRegister
                                            onClick={() =>
                                                OnActiveToggle(false)
                                            }
                                            to={RoutePaths.CreateYourAccount}
                                        >
                                            Sign Up
                                        </StyledRegister>
                                    </MenuLiButton>
                                </>
                            ) }

                            { WatchSecondary.items.map((menuItem, index) => (
                                <MenuLi key={ index }>
                                    <StyledLink
                                        onClick={() => OnActiveToggle(false)}
                                        to={ menuItem.Link }
                                    >
                                        { menuItem.Title }
                                    </StyledLink>
                                </MenuLi>
                            )) }

                            <MenuLi>
                                <StyledLink
                                    onClick={() => OnActiveToggle(false)}
                                    to={RoutePaths.BrowseArticles}
                                >
                                    Read
                                </StyledLink>
                            </MenuLi>

                            <MenuLi>
                                <StyledLink
                                    onClick={() => OnActiveToggle(false)}
                                    to="https://shop.ickonic.com"
                                    target="_blank"
                                >
                                    Shop
                                    {ExternalLink()}
                                </StyledLink>
                            </MenuLi>

                            { props.isAuthenticated && (
                                <>
                                    <MenuLi>
                                        <StyledLink
                                            onClick={() =>
                                                OnActiveToggle(false)
                                            }
                                            to="/Account"
                                        >

                                            <MenuIcon>
                                                <IconProfileThin />
                                            </MenuIcon>

                                            Account
                                        </StyledLink>
                                    </MenuLi>
                                    <MenuLi>
                                        <StyledLink
                                            onClick={() =>
                                                OnActiveToggle(false)
                                            }
                                            to={RoutePaths.HelpCentre}
                                        >
                                            <MenuIcon>
                                                <IconQuestionThin />
                                            </MenuIcon>

                                            Help Centre
                                        </StyledLink>
                                    </MenuLi>
                                    <MenuLi>
                                        <MenuA onClick={OnLogout}>
                                            Log Out
                                        </MenuA>
                                    </MenuLi>
                                </>
                            ) }
                        </MenuUl>
                    </HamburgerMenu>
                </ListRight>
            </Navigation>
        </Header>
    );
}

export default NavBar;
