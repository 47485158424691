import React, { useEffect, useRef } from "react";
import styled from "styled-components";

// Global Vars
import { Colours } from "../../Constants/Colours";

// Layouts
import HeroOneColumn from "../../Components/Layouts/HeroOneColumn";
import FeatureRoadmap from "../../Components/Layouts/FeatureRoadmap";

// Elements
import PlayButtonFractalLines from "../../Assets/Icons/PlayButtonFractalLines";
import ButtonGradientNavLink from "../../Components/UI/Buttons/ButtonGradientNavLink";
import {
    IconTripleColumn,
    IconBrowser,
    IconListBullet,
    IconPenWriting,
    IconTimeBackwards,
    IconInformationBubble,
    IconShopTag,
    IconRefreshCheckmark,
    IconMugHot,
    IconChatFilled,
    IconTranslation,
    IconPenAndPaper,
    IconPlayButtonCircled,
    IconStarHollow,
    IconMobilePhone,
    IconTV,
    IconSM58,
    IconDoubleColumn,
    IconHeartHollow,
    IconListCheckmarks
} from "../../Assets/Icons/Icons";
import {ResponsiveBreakpoints} from "../../Constants/ResponsiveBreakpoints";
import {Ease} from "../../Constants/EasingCurves";

const LPLifetimeMembership = styled.div`

    /* General Elements
    ========================== */

    .button-ickonic--gradient {
        position: relative;
        z-index: 1;
        font-family: "Rubik";
        text-transform: uppercase;
        animation: boxShadowGlowPulse 1.5s infinite ease-in-out;
        transition: transform .15s ease-in-out;
    }

    // Gradient Heading (H1)

    .lifetime-membership__heading {
        font-family: "Outfit";
        text-align: center;
        font-weight: bold;
        position: relative;
        font-size: calc(36rem / 16);
        line-height: 1.3;
        z-index: 1;
        background: linear-gradient(90deg, rgb(232,187,39) 0%, rgba(187, 54, 184, 1) 100%);
        -webkit-background-clip: text;
        color: transparent;
    }

    @media screen and (min-width: calc(${ResponsiveBreakpoints.DesktopBreakpoint}em / 16)) {
        .lifetime-membership__heading {
            font-size: calc(40rem / 16);
        }
    }

    @media screen and (min-width: calc(${ResponsiveBreakpoints.WideScreenBreakpoint}em / 16)) {
        .lifetime-membership__heading {
            font-size: calc(44rem / 16);
        }
    }

    .lifetime-membership__svg-wrapper {
        overflow: hidden;
        position: relative;
        padding: calc(47rem/16) 0 2rem 0;
        background: black;
        .ickonic-logo--primary {
            position: absolute;
            top: calc(3rem/16);
            left: calc(50% - (56rem/16));
            z-index: 10;
            width: calc(112rem/16);
            height: calc(47rem/16);
            padding: calc(4rem/16);
            * {
                fill: rgba(255,255,255,0.7);
            }
        }

        .vector--play-button-fractal-lines {
            width: 400vw;
            height: 400vw;
            position: absolute;
            top: -80vw;
            left: -120vw;
            z-index: 0;

            path {
                stroke: rgba(187, 54, 184, 0.035);
                transition: stroke 1.5s ${Ease.Smooth};
            }

            @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}em / 16)) {
                width: 300vw;
                height: 300vw;
                top: -100vw;
                left: -90vw;
            }

            @media screen and (min-width: calc(${ResponsiveBreakpoints.WideScreenBreakpoint}em / 16)) {
                width: 250vw;
                height: 250vw;
                top: -80vw;
                left: -70vw;
            }

            @media screen and (min-width: calc(${ResponsiveBreakpoints.SuperWideBreakpoint}em / 16)) {
                top: -90vw;
            }
        }

        .lifetime-membership--hero {
            max-width: calc(750rem / 16);
            padding: 0 0 1rem 0;
            margin: 0 auto;

            .button-ickonic {
                animation: boxShadowGlowPulse 1.5s infinite ease-in-out;
            }

            @media screen and (min-width: calc(${ResponsiveBreakpoints.DesktopBreakpoint}em / 16)) {
                padding: 7rem 1rem 6rem 1rem;
                max-width: none;
            }
        }
    }

    .lifetime-membership__letter {
        background: linear-gradient(90deg, rgba(229,174,44, 1) 0%, rgba(187, 54, 184, 1) 100%);

        h2 {
            margin-top: 0;
            font-family: "Rubik";
        }

        .letter__inner {
            max-width: calc(900rem / 16);
            margin: 0 auto;
            padding: 1.5rem;
            background: rgba(0, 0, 0, 0.6);

            h2 {
                margin-top: 0;
                color: white;
                line-height: 1.1;
                font-weight: normal;
            }

            p {
                font-family: "Rubik";

                &:first-of-type {
                    margin-top: 0 !important;
                }

                &:last-of-type {
                    margin-bottom: 0 !important;
                }
            }
        }

        @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}em / 16)) {
            padding: 2rem;
            .letter__inner {
                border-radius: calc(10rem / 16);
            }
        }

        @media screen and (min-width: calc(${ResponsiveBreakpoints.DesktopBreakpoint}em / 16)) {
            margin: 0;
        }
    }

    > .layout--hero-one-column {
        background: rgba(15,15,15, 1) !important;
    }

    .lifetime-membership__icon-grid {
        background: rgba(15,15,15, 1);
        position: relative;
        z-index: 1;
        padding: 2rem;
        border-radius: calc(20rem / 16);

        .button-ickonic {
            margin-top: 2rem !important;
        }

        .icon-grid {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 1rem 0;

            .icon-grid__item {
                width: calc(50% - .5rem);
                padding: 1rem 0;

                p {
                    font-size: calc(14rem / 16);
                    text-align: center;
                    max-width: calc(230rem / 16);
                    margin: 1rem auto 0 auto;
                }

                svg {
                    width: calc(45rem / 16);
                    height: calc(45rem / 16);
                    display: block;
                    margin: 0 auto;
                }
            }
        }

        @media screen and (min-width: calc(${ResponsiveBreakpoints.TabletBreakpoint}em / 16)) {
            .icon-grid {
                padding: 2rem 0;

                .icon-grid__item {
                    width: calc(20% - .5rem);

                }
            }
        }

        @media screen and (min-width: calc(${ResponsiveBreakpoints.DesktopBreakpoint}em / 16)) {
            padding: 3rem;
            .icon-grid {
                .icon-grid__item {
                    width: calc(20% - .5rem);
                    padding: 1rem;

                    p {
                        font-size: calc(16rem / 16);
                    }
                }
            }
        }
    }

    .lifetime-membership--roadmap {
        background: rgba(25, 25, 25, 1);
        padding: 1rem 1rem 2rem 1rem;
        padding: 1rem 1rem 2rem 1rem;

        p,
        span,
        h3,
        h4 {
            color: white;
        }

        svg {
            * {
                fill: ${Colours.Accent};
            }
        }

        .button-ickonic {
            margin-top: 2rem !important;
        }
        
        .layout__item {
            height: calc(210rem/16);
        }

        @media screen and (min-width: calc(${ResponsiveBreakpoints.TabletBreakpoint}em / 16)) {
            padding: 3rem;
        }
    }
`;

const LifetimeMembership = () => {
    let heroSvgPaths: NodeListOf<SVGPathElement>[] = [];

    const
        heroSvgRippleDelay: number = 100,
        pageRef = useRef<HTMLDivElement | null>(null),
        StyledLogo = styled.svg`
            width: 100%;
            height: 100%;
        `,
        svgDefs = <>
            <defs>
                <linearGradient id="goldToPinkGradient" gradientTransform="rotate(90)">
                    <stop offset="0%" stop-color="rgba(183,96,180,1)"/>
                    <stop offset="100%" stop-color="rgba(187,54,184,1)"/>
                </linearGradient>
            </defs>
        </>,

        augmentPathStroke = () => {
            const
                colorStart: number[] = [187, 54, 184],
                colorEnd: number[] = [255, 188, 16],
                arrayLength: number = heroSvgPaths[0].length,
                interpolateColor = (color1: number[], color2: number[], factor: number) => {
                    const result = color1.slice();
                    for (let i = 0; i < 3; i++) {
                        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
                    }
                    return `rgba(${result[0]},${result[1]},${result[2]},0.12)`;
                };

            heroSvgPaths[0].forEach((pathElement, index) => {
                setTimeout(() => {
                    if (index >= 0) {
                        const factor = index / (arrayLength - 1); // Calculate factor
                        pathElement.style.stroke = interpolateColor(colorStart, colorEnd, factor);
                    }
                }, index * heroSvgRippleDelay);

                // Removal
                setTimeout(() => {
                    if (index >= 0) {
                        pathElement.style.stroke = 'rgba(187,54,184,0.05)';
                    }
                }, index * heroSvgRippleDelay + 3100);
            });
        };

    useEffect(() => {
        const baseDelay: number = 7000;

        // Get all Hero SVG Paths and add to an array
        if (heroSvgPaths.length == 0 && pageRef !== null && pageRef.current !== null) {
            let tempPaths: NodeListOf<SVGPathElement> = pageRef.current.querySelectorAll('.vector--play-button-fractal-lines path');
            heroSvgPaths.push(tempPaths);
        }

        // Once var is populated
        if (heroSvgPaths.length !== 0 && pageRef !== null && pageRef.current !== null) {

            // Initial Wave
            augmentPathStroke();
            const
                waveInterval: NodeJS.Timer = setInterval(() => {
                    augmentPathStroke();
                }, baseDelay);

            return () => {
                clearInterval(waveInterval);
            };
        }
    }, []);

    return (
        <LPLifetimeMembership ref={pageRef} className="page page--landing page--landing__lifetime-membership">
            <div className="lifetime-membership__svg-wrapper">

                <StyledLogo
                    className="ickonic-logo--primary"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 2986 809.9"
                    version="1.1"
                    viewBox="0 0 2986 809.9"
                    xmlSpace="preserve"
                >
                    <defs>
                        <linearGradient id="goldToPinkGradient" gradientTransform="rotate(90)">
                            <stop offset="0%" stop-color="rgba(183,96,180,1)"/>
                            <stop offset="100%" stop-color="rgba(187,54,184,1)"/>
                        </linearGradient>
                    </defs>

                    <path
                        d="M3600 15990.5c-463-52-838-238-1092-539-226-269-356-595-410-1030-10-84-13-586-13-2480 0-2621-4-2434 61-2728 106-477 361-849 730-1068 550-326 1508-326 2058 0 362 215 617 582 725 1045 62 263 64 297 68 1059l4 702H4560v-582c-1-618-7-790-35-903-52-215-177-349-381-411-74-23-310-26-394-5-247 61-387 246-420 556-13 126-13 4571 0 4690 15 126 34 198 80 291 67 137 189 233 342 269 84 20 293 20 377 0 152-36 279-138 345-278 74-156 85-277 86-910v-437l583 2 582 3v540c0 573-4 638-51 873-92 462-324 834-662 1064-213 145-504 243-817 278-107 12-488 11-595-1zM26730 15990.5c-463-52-838-238-1092-539-226-269-356-595-410-1030-10-84-13-586-13-2480 0-2621-4-2434 61-2728 106-477 361-849 730-1068 550-326 1508-326 2058 0 362 215 617 582 725 1045 62 263 64 297 68 1059l4 702h-1171v-582c-1-618-7-790-35-903-52-215-177-349-381-411-74-23-310-26-394-5-247 61-387 246-420 556-13 126-13 4571 0 4690 15 126 34 198 80 291 67 137 189 233 342 269 84 20 293 20 377 0 152-36 279-138 345-278 74-156 85-277 86-910v-437l583 2 582 3v540c0 573-4 638-51 873-92 462-324 834-662 1064-213 145-504 243-817 278-107 12-488 11-595-1zM0 11951.5v-3940h1240v7880H0v-3940zM6470 11951.5v-3940h1240v2507l247 499c230 464 248 497 256 468 5-16 239-804 521-1750l513-1719 646-3c365-1 647 2 647 7 0 4-335 1126-745 2491l-745 2483 735 1442c404 794 735 1445 735 1449 0 11-1225 7-1236-4-5-5-361-749-789-1653l-780-1643-3 1653-2 1653H6470v-3940zM18330 11951.5v-3940h1110v2877c0 1742 4 2873 9 2867 5-5 336-1276 736-2824s733-2839 742-2867l14-53h1269v7880h-1100v-2360c0-1453-4-2360-9-2360s-12 8-15 18c-3 9-274 1068-602 2352s-598 2338-600 2342c-3 5-354 8-780 8h-774v-3940zM23130 11951.5v-3940h1240v7880h-1240v-3940z"
                        transform="matrix(.1 0 0 -.1 0 1600)"
                    ></path>
                    <path
                        d="M13635 15831.5c-818-66-1540-350-2175-856-173-138-475-441-613-614-725-911-1009-2064-791-3205 264-1379 1259-2522 2594-2980 1015-348 2136-264 3087 230 631 328 1185 848 1556 1460 294 484 471 1004 544 1595 13 109 17 218 17 440 0 315-11 455-59 716-126 680-439 1331-896 1859-118 137-371 382-503 487-607 486-1314 777-2081 858-138 15-547 20-680 10zm620-915c244-28 441-73 664-150 1009-351 1768-1221 1976-2265 75-376 75-831 0-1205-209-1044-979-1920-1990-2264-299-102-564-149-895-158-311-8-552 17-830 87-535 135-1002 402-1400 800-217 217-371 420-514 678-391 705-481 1551-250 2337 243 824 864 1532 1659 1892 290 132 661 229 980 256 12"
                        transform="matrix(.1 0 0 -.1 0 1600)"
                    ></path>
                    <path
                        d="M12870 13880.5c-124-13-236-75-307-167-67-88-63 26-63-1792 0-1823-5-1698 66-1793 40-54 130-117 207-144 39-13 81-18 167-18 100 0 123 4 176 26 88 37 2781 1647 2831 1692 93 85 128 232 83 350-46 123 7 88-1495 984-941 562-1390 824-1435 839-77 26-141 32-230 23z"
                        transform="matrix(.1 0 0 -.1 0 1600)"
                    ></path>
                </StyledLogo>

                {/** Hero **/}
                <HeroOneColumn
                    body={(
                        <div className="lifetime-membership--hero">
                            {PlayButtonFractalLines()}

                            <h1 className="lifetime-membership__heading">
                            Big changes are coming in 2024.
                            </h1>

                            <p style={{
                                fontSize: 'calc(22rem/16)',
                                lineHeight: '1.5',
                                color: '#f0f0f0',
                                marginTop: '2rem',
                                textAlign: 'center',
                                margin: '1rem auto',
                                position: 'relative',
                                zIndex: 1
                            }}>
                                We’re raising capital to transform Ickonic into a leading global information platform.
                            </p>

                            <div
                                style={{
                                    padding: 'calc(16rem/16)',
                                    boxShadow: `0 0 0 calc(2rem/16) rgba(255,201,63,0.4)`,
                                    borderRadius: 'calc(5rem/16)',
                                    marginBottom: '2rem',
                                }}
                            >
                                <p style={{
                                    fontSize: 'calc(18rem/16)',
                                    lineHeight: '1.5',
                                    textAlign: 'center',
                                    position: 'relative',
                                    margin: '0 0 16px 0',
                                    zIndex: 1
                                }}>
                                    Unlock <span
                                    style={{fontWeight: 'bold', color: Colours.Accent}}>lifetime access</span> to
                                    everything we offer for a one-time fee of <span
                                    style={{fontWeight: 'bold', color: Colours.Accent}}>£500</span>.
                                </p>


                                <div style={{
                                    background: 'black',
                                    border: '1px solid rgba(229,174,44, 1)',
                                    height: '25px',
                                    position: 'relative',
                                    borderRadius: '10px',
                                    maxWidth: '60%',
                                    margin: '0 auto'
                                }}>
                                    <div style={{
                                        background: 'rgba(229,174,44, 1)',
                                        width: '52%',
                                        position: 'absolute',
                                        height: '100%',
                                        borderRadius: '10px',
                                        boxShadow: 'inset 0 0 0 4px black'
                                    }} />
                                </div>

                                <p style={{
                                    fontSize: 'calc(16rem/16)',
                                    lineHeight: '1.5',
                                    textAlign: 'center',
                                    position: 'relative',
                                    margin: '14px 0 0 0',
                                    zIndex: 1
                                }}>
                                    Only 145 / 300 spots remaining!
                                </p>
                            </div>

                            <ButtonGradientNavLink
                                link="https://buy.stripe.com/9AQeYCeeU2fI62I6oq"
                                text="Upgrade Your Account"
                                openInNewWindow={true}
                            />
                        </div>
                    )}
                    maxWidth={800}
                />
            </div>

            {/** Letter **/}
            <div className="lifetime-membership__letter">
                <div className="letter__inner">
                    <h2>
                        From Jaymie Icke:
                    </h2>

                    <p style={{
                        fontSize: 'calc(20rem/16)',
                        lineHeight: '1.7',
                        color: '#f0f0f0',
                        marginTop: '2rem',
                        margin: '1.5rem auto',
                        position: 'relative',
                        zIndex: 1
                    }}>
                        We’re putting together a host of features and revisions that will vastly improve the experience
                        and reach of Ickonic.com. This includes new films & series, reworked apps, and
                        expanding our team to realize every facet of our exciting vision for the future.
                    </p>

                    <p style={{
                        fontSize: 'calc(20rem/16)',
                        lineHeight: '1.7',
                        color: '#f0f0f0',
                        marginTop: '2rem',
                        margin: '1.5rem auto',
                        position: 'relative',
                        zIndex: 1
                    }}>
                        To transform Ickonic from a niche service into a formidable challenger of main-stream
                        information, we're offering an extremely unique package of <strong>lifetime services</strong> to a
                        limited number of subscribers. The full opportunity is detailed below:
                    </p>
                </div>
            </div>

            {/** What you get **/}
            <HeroOneColumn
                body={(
                    <div className="lifetime-membership__icon-grid">
                        <h1 className="lifetime-membership__heading">
                            Lifetime Membership Perks
                        </h1>

                        <div className="icon-grid">
                            <div className="icon-grid__item">
                                {IconRefreshCheckmark({iconFill: Colours.Accent, defs: svgDefs})}
                                <p>
                                    Unlimited access to Ickonic's platform (can be passed down)
                                </p>
                            </div>

                            <div className="icon-grid__item">
                                {IconInformationBubble({iconFill: Colours.Accent, defs: svgDefs})}
                                <p>
                                    Exclusive, annual online event including Q&A w/ David, Gareth, Jaymie & More
                                </p>
                            </div>

                            <div className="icon-grid__item">
                                {IconPenWriting({iconFill: Colours.Accent, defs: svgDefs})}
                                <p>
                                    Personally signed Copy of David Icke’s new book
                                </p>
                            </div>

                            <div className="icon-grid__item">
                                {IconMugHot({iconFill: Colours.Accent, defs: svgDefs})}
                                <p>
                                    Free Ickonic Mug
                                </p>
                            </div>

                            <div className="icon-grid__item">
                                {IconTripleColumn({iconFill: Colours.Accent, defs: svgDefs})}
                                <p>
                                    Your name on our member's wall at our UK studio
                                </p>
                            </div>

                            <div className="icon-grid__item">
                                {IconBrowser({iconFill: Colours.Accent, defs: svgDefs})}
                                <p>
                                    Your name on the Ickonic.com member's wall web-page
                                </p>
                            </div>

                            <div className="icon-grid__item">
                                {IconListBullet({iconFill: Colours.Accent, defs: svgDefs})}
                                <p>
                                    Your name on the credits of ALL our productions
                                </p>
                            </div>

                            <div className="icon-grid__item">
                                {IconTimeBackwards({iconFill: Colours.Accent, defs: svgDefs})}
                                <p>
                                    Access to Original Films 48 hours before other members
                                </p>
                            </div>

                            <div className="icon-grid__item">
                                {IconSM58({iconFill: Colours.Accent, defs: svgDefs})}
                                <p>
                                    Access to Ickonic and David Icke events 48 hours before general sale
                                </p>
                            </div>

                            <div className="icon-grid__item">
                                {IconShopTag({iconFill: Colours.Accent, defs: svgDefs})}
                                <p>
                                    20% discount on all purchases on the Ickonic Shop
                                </p>
                            </div>
                        </div>

                        <ButtonGradientNavLink
                            link="https://buy.stripe.com/9AQeYCeeU2fI62I6oq"
                            text="Upgrade Your Account"
                            openInNewWindow={ true }
                        />
                    </div>
                )}
                maxWidth={1300}
            />

            {/** What you get **/}
            <div className="lifetime-membership--roadmap">
                <h1 className="lifetime-membership__heading">
                    Roadmap
                </h1>

                <p style={{
                    fontSize: 'calc(18rem/16)',
                    lineHeight: '1.7',
                    color: '#f0f0f0',
                    marginTop: '2rem',
                    textAlign: 'center',
                    margin: '2rem auto',
                    position: 'relative',
                    maxWidth: 650,
                    zIndex: 1,
                    padding: 'calc(16rem/16)',
                    boxShadow: `0 0 0 calc(2rem/16) rgba(255,201,63,0.4)`,
                    borderRadius: 'calc(5rem/16)',
                    marginBottom: '2rem',
                }}>
                    While this is by no means an exhaustive list of what's to come, we're excited to share our plans for 2024 and beyond!
                </p>

                <FeatureRoadmap
                    features={[
                        {
                            title: 'Brand New Series & Films',
                            body: "Our pipeline slows for nothing! Lifetime members get access to new films 48 hours early.",
                            icon: IconDoubleColumn({iconFill: Colours.Accent}),
                            timeframe: 'June 2024'
                        },
                        {
                            title: 'Unannounced USA-Focused Content',
                            body: "Keeping in step with our expanding global reach, we're getting a great deal closer to our American audience in 2024. More info on this in the coming weeks.",
                            icon: IconStarHollow({iconFill: Colours.Accent}),
                            timeframe: 'July 2024'
                        },
                        {
                            title: 'Weekly Live Streams',
                            body: 'Weekly Live Member Q&A\'s with our hosts where your questions are answered in real time.',
                            icon: IconPlayButtonCircled({iconFill: Colours.Accent}),
                            timeframe: 'July 2024'
                        },
                        {
                            title: 'Brand New Members Area',
                            body: 'A brand new members experience will allow you to connect with members all over the world and bring a new community feel to the Ickonic channel.',
                            icon: IconHeartHollow({iconFill: Colours.Accent}),
                            timeframe: 'Aug 2024'
                        },
                        {
                            title: 'English Subtitles on All Content',
                            body: 'Potentially our #1 requested feature. We hear you!',
                            icon: IconPenAndPaper({iconFill: Colours.Accent}),
                            timeframe: 'Oct 2024'
                        },
                        {
                            title: 'Members Forum',
                            body: 'A subscriber-only "town hall". Converse with other members (civilly, of course) on a variety of topics, Ickonic adjacent and otherwise.',
                            icon: IconChatFilled({iconFill: Colours.Accent}),
                            timeframe: 'Q4 2024'
                        },
                        {
                            title: 'Rebuilt Ickonic Web / Android / iOS apps',
                            body: 'Brand new, from the ground up, and with a host of new and useful features.',
                            icon: IconMobilePhone({iconFill: Colours.Accent}),
                            timeframe: 'Q4 2024'
                        },
                        {
                            title: 'Rebuilt Ickonic TV apps',
                            body: 'More platforms, and a world-class experience for each.',
                            icon: IconTV({iconFill: Colours.Accent}),
                            timeframe: 'Q1 2025'
                        },
                        {
                            title: 'Spanish Subtitles on All Original Films',
                            body: 'We want to make Ickonic accessible to as many people as possible! This is just the first step towards multi-language support across the entire platform.',
                            icon: IconTranslation({iconFill: Colours.Accent}),
                            timeframe: 'TBA'
                        },
                        {
                            title: 'So much more to come',
                            body: "This is only a slice of what we have planned. We will adapt this information to a more permanent location on the website that we'll continue to update and amend.",
                            icon: IconListCheckmarks({iconFill: Colours.Accent}),
                            timeframe: 'TBA'
                        }
                    ]}
                />

                {/*<ButtonGradientNavLink*/}
                {/*    link="/"*/}
                {/*    text="Upgrade Your Account"*/}
                {/*    background="linear-gradient(90deg, rgba(187,54,184,1) 0%, rgba(99,25,96,1) 100%)"*/}
                {/*    color="white"*/}
                {/*/>*/}
            </div>
        </LPLifetimeMembership>
    );
};

export default LifetimeMembership;
