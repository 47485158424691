import { RoutePaths } from "./RoutePaths";

export const PagesWithoutNavigation = [
    RoutePaths.CreateYourAccount.toLowerCase(),
    RoutePaths.CreateYourAccountGameChanger.toLowerCase(),
    RoutePaths.CreateYourAccountTruthSeeker.toLowerCase(),
    RoutePaths.ChoosePlan.toLowerCase(),
    RoutePaths.ConfirmSubscription.toLowerCase(),
    RoutePaths.Renew3DSConfirm.toLowerCase(),
    RoutePaths.Login().toLowerCase(),
    RoutePaths.AndroidTV.toLowerCase(),
    RoutePaths.SamsungTV.toLowerCase(),
    RoutePaths.Roku.toLowerCase(),
    RoutePaths.Firestick.toLowerCase(),
    RoutePaths.LifetimeMembership.toLowerCase(),
    RoutePaths.LifetimeMembership + '/'.toLowerCase()
];
