import styled from "styled-components";
import IPanelDTO from "../../../Models/DTOs/IPanelDTO";
import { PanelPropertyType } from "../../../Models/Enums/IPanelPropertyType";
import Heading from "../Text/Heading";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { Colours } from "../../../Constants/Colours";
import PrimaryText from "../Text/PrimaryText";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
import PlaceHolder from "../../../Assets/Images/Placeholders/IckonicPlaceholderLandscape.jpg";
import React from "react";
import { RoutePaths } from "../../../Constants/RoutePaths";
import { Fonts } from "../../../Constants/Fonts";
import NavLinkPinkButtonFat from "../Buttons/NavLinkPinkButtonFat";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";

const Container = styled.div`
    
    background: #191919;
    .sub {
        font-weight: 500;
        font-size: 18pt;
    }

    h2 {
        margin: 0 0 2rem 0;
    }

    li,
    p {
        margin: 0 0 16px;
        font-family: ${ Fonts.Primary };
        font-size: 14pt;
        line-height: 1.5;
        color: ${Colours.Text};
        &:last-of-type {
            margin: 0;
        }
    }

    li::marker{
        color: ${Colours.IckonicPink}
    }
    

`;

const VideoContainer = styled.div`
    height: 52vw;
    max-height: 437px;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        height: 25.5vw;
        //max-height: 437px;
    }
`;

const TextContainer = styled.div`
    
    margin: 2rem auto 0 auto;
    order: 1;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        margin: 0 auto 2rem auto;
        order: 0;
        margin: 0;
        width: calc(50% - 1.5rem);
    }
`;

const ContentContainer = styled.div`
    max-width: 1696px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        padding: 3rem;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }em/16)) {
        padding: 4rem 3rem;
    }  
    
    & > div {
        max-width: 776px;
        width: 100%;
    }
        
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        flex-direction: row;
        justify-content: space-between;
        
        & > div {
            width: calc(50% - 1rem);
        }

    }
`;

const ButtonContainer = styled.div`
    a,
    button {
        margin-top: 2rem;
    }
`;

function PanelVideo(props: { panel: IPanelDTO; freeDaysTrial: number }) {
    const properties = props.panel.Properties;
    const url = properties.find(
        (x) => x.PropertyType === PanelPropertyType.VideoUrl
    );
    const background = properties.find(
        (x) => x.PropertyType === PanelPropertyType.Image
    );

    return (
        <Container>
            <ContentContainer className="panel-50-50__content-container">
                <TextContainer>
                    {props.panel.Properties.filter(
                        (x) => x.PropertyType === PanelPropertyType.Title
                    ).map((property, index) => {
                        if (index === 0) {
                            return (
                                <Heading key={"T" + property.Id} type={ HeadingType.H2 }>
                                    {property.Value.replace(
                                        "{freetrialdays}",
                                        props.freeDaysTrial.toString()
                                    )}
                                </Heading>
                            );
                        }

                        return (
                            <PrimaryText class={"sub"} key={"T" + property.Id}>
                                {property.Value.replace(
                                    "{freetrialdays}",
                                    props.freeDaysTrial.toString()
                                )}
                            </PrimaryText>
                        );
                    })}

                    {props.panel.Properties.filter(
                        (x) => x.PropertyType === PanelPropertyType.BulletPoints
                    ).map((property, index) => {
                        const buttonText = property.Value.split(";");

                        return (
                            <ul key={"i" + index}>
                                {buttonText.map((text) => {
                                    return <li key={text}>{text}</li>;
                                })}
                            </ul>
                        );
                    })}

                    {props.panel.Properties.filter(
                        (x) => x.PropertyType === PanelPropertyType.Text
                    ).map((property) => {
                        return (
                            <React.Fragment key={"T" + property.Id}>
                                {parse(
                                    DOMPurify.sanitize(
                                        property.Value.replace(
                                            "{freetrialdays}",
                                            props.freeDaysTrial.toString()
                                        )
                                    )
                                )}
                            </React.Fragment>
                        );
                    })}

                    <ButtonContainer>
                        {props.panel.Properties.filter(
                            (x) =>
                                x.PropertyType === PanelPropertyType.ButtonText
                        ).map((property) => {
                            return (
                                <NavLinkPinkButtonFat key={property.Id} to={RoutePaths.CreateYourAccount}>
                                    {property.Value.replace(
                                        "{freetrialdays}",
                                        props.freeDaysTrial.toString()
                                    )}
                                </NavLinkPinkButtonFat>
                            );
                        })}
                    </ButtonContainer>
                </TextContainer>

                <VideoContainer>
                    <VideoPlayer
                        src={url ? url.Value : ""}
                        poster={background ? background.Value : PlaceHolder}
                    />
                </VideoContainer>
            </ContentContainer>
        </Container>
    );
}

export default PanelVideo;
