import { Suspense, useContext, useEffect, useState } from "react";
import { HeadingType } from "../../Models/Enums/HeadingType";
import { Colours } from "../../Constants/Colours";
import styled from "styled-components";
import AccountSection from "../../Components/UI/Account/AccountSection";
import PinkButton from "../../Components/UI/Buttons/PinkButton";
import PrimaryText from "../../Components/UI/Text/PrimaryText";
import Heading from "../../Components/UI/Text/Heading";
import StripeCard from "../../Components/UI/StripeCard";
import { PaymentMethod } from "@stripe/stripe-js";
import AuthContext, { UserSnapshot } from "../../Store/auth-context";
import { AddPaymentMethod } from "../../Api/Stripe";
import { DisconnectFromAllDevices, GetYourAccountDTO } from "../../Api/Account";
import { IYourAccountDTO } from "../../Models/DTOs/IYourAccountDTO";
import {
    CanUpgrade,
    GetAccessLevelTitle,
    GetPlanPeriod,
    GetPlanSubText,
    GetPlanType,
    GetRenewStatusTitle,
    PlanInterval,
    SetLoginAfterToNow,
    SetTitle,
    SymbolFromCurrency,
} from "../../Helpers/Utility";
import EditPerson from "../../Components/UI/Account/EditPerson";
import EditEmail from "../../Components/UI/Account/EditEmail";
import EditCommercialEmails from "../../Components/UI/Account/EditCommericalEmails";
import ChangePasswordForm from "../../Components/UI/Account/ChangePasswordForm";
import GreyButton from "../../Components/UI/Buttons/GreyButton";
import UpgradeModal from "../../Components/UI/Modals/UpgradeModal";
import CancelModal from "../../Components/UI/Modals/CancelModal";
import { SubscriptionStatus } from "../../Models/Enums/SubscriptionStatus";
import { Await, defer, redirect, useLoaderData } from "react-router-dom";
import { AccountAuth } from "../../Helpers/RouteAuth";
import { IUserDetails } from "../../Models/IUserDetails";
import TextLoader from "../../Components/UI/PageLoaders/TextLoader";
import { RoutePaths } from "../../Constants/RoutePaths";
import { ISubscriptionDTO } from "../../Models/DTOs/ISubscriptionDTO";
import { AxiosError } from "axios";
import EditDailyNewsletter from "../../Components/UI/Account/EditDailyNewsletter";
import moment from "moment";
import { toast } from "react-toastify";
import { Fonts } from "../../Constants/Fonts";
import NavLinkPinkButton from "../../Components/UI/Buttons/NavLinkPinkButton";
import PopUp from "../../Components/UI/Modals/PopUp";
import AccountRow from "../../Components/UI/Account/AccountRow";
import PinkTextButton from "../../Components/UI/Buttons/PinkTextButton";
import { ResponsiveBreakpoints } from "../../Constants/ResponsiveBreakpoints";
import Card from "../../Components/UI/Card";
import { NeedsRenew } from "../../Helpers/UserUtility";
import { InvoiceStatus } from "../../Models/Enums/InvoiceStatus";

const Container = styled.section`
    padding: 1rem;
    button {
        margin: 0 0 1rem 0;
    }
`;

const MainHeading = styled(Heading)`
    text-align: center;
    margin: 0;
    padding: 2rem 1rem;
    background: ${Colours.Secondary};
    @media screen and (min-width: calc(${ResponsiveBreakpoints.TabletBreakpoint}rem/16)) {
        font-size: 25pt;
    }
`;

const ContentSpit = styled.div`
    > h3 {
        margin: 0 0 calc(8rem/16) 0;
    }
`;

const ContentContainer = styled.div`

`;

const StripeCardContainer = styled.div`

    max-width: calc(600rem/16);
`;

const PlanTable = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const PlanElem = styled.div`
    padding: 0 0 calc(10rem/16) 0;
    width: 100%;
    font-family: ${Fonts.Primary};
    h4 {
        margin: 0;
        color: ${Colours.TextDarker};
        font-size: 12pt;
    }

    p {
        font-size: 12pt;
        color: ${Colours.TextBright};
        margin: calc(5rem/16) 0 0 0;
    }
    
    @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}rem/16)) {
        flex:1;
        min-width: 30%;
        padding: 0 calc(25rem/16) calc(25rem/16) 0;
    }
`;

async function GetYourAccountDetails(userDetails: IUserDetails, controller: AbortController) {
    const result = await GetYourAccountDTO(
        userDetails.AspNetUserId,
        userDetails.Access_Token,
        controller
    );

    if (result === null || result instanceof AxiosError) {
        return null;
    }

    SetTitle("Account");
    return result;
}

function AccountScreen() {
    const { accountPromise } = useLoaderData() as {
        accountPromise: Promise<IYourAccountDTO>;
    };
    const authCtx = useContext(AuthContext);
    const controller = new AbortController();
    const [displayUpgrade, setDisplayUpgrade] = useState<boolean>(false);
    const [displayCancel, setDisplayCancel] = useState<boolean>(false);
    const [upgradeSuccess, setUpgradeSuccess] = useState<boolean>(false);
    const [account, setAccount] = useState<Promise<IYourAccountDTO | null>>(accountPromise);

    const currentPlan = GetPlanType(
        authCtx.UserDetails.ContentAccessType,
        authCtx.UserDetails.Period
    );

    const currentPlanTitle =
        currentPlan !== undefined ? GetPlanPeriod(currentPlan) + "ly Plan" : "";

    const currencySymbol = SymbolFromCurrency(
        authCtx.UserDetails.Currency
    );

    useEffect(() => {
        if (!upgradeSuccess) {
            return;
        }
        const result = GetYourAccountDetails(authCtx.UserDetails, controller)
        setAccount(result)
        setUpgradeSuccess(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SetUpgradeSuceess]);

    const canUpgrade = CanUpgrade(authCtx.UserDetails);

    function SetDisplayUpgrade(value: boolean) {
        setDisplayUpgrade(value);
    }

    function SetDisplayCancel(value: boolean) {
        setDisplayCancel(value);
    }

    function SetUpgradeSuceess(value: boolean) {
        setUpgradeSuccess(value);
    }

    async function AddStripePaymentMethod(paymentMethod: PaymentMethod) {
        const result = await AddPaymentMethod(
            authCtx.UserDetails.AspNetUserId,
            paymentMethod.id,
            controller
        );

        if (result instanceof AxiosError) {
            return false;
        }

        authCtx.Update(authCtx.UserDetails.Access_Token);
        return result;
    }

    async function DisconnectOtherDevices() {
        let result = await DisconnectFromAllDevices(authCtx.UserDetails.Access_Token, controller);
        if (result) {
            toast.success("Success! Your other website sessions will be disconnected.");
            SetLoginAfterToNow();
        }
    }

    return (
        <Suspense fallback={<TextLoader />}>
            <Await resolve={account}>
                {(data) => {
                    const accountData = data as IYourAccountDTO;

                    //Needs to wait for context to be loaded
                    if (!authCtx.UserDetails || Object.keys(authCtx.UserDetails).length === 0) {
                        return <TextLoader />;
                    }

                    const disableCancel =
                        accountData === null ||
                        accountPromise === undefined ||
                        accountData.LatestInvoiceStatus == InvoiceStatus.Draft ||
                        authCtx.UserDetails.SubscriptionStatus === SubscriptionStatus.Cancelled ||
                        authCtx.UserDetails.SubscriptionStatus === SubscriptionStatus.None ||
                        authCtx.UserDetails.SubscriptionStatus === SubscriptionStatus.Incomplete_Expired;

                    return (
                        <>
                            <MainHeading>
                                Account Settings
                            </MainHeading>

                            <Container>
                                <AccountSection
                                    title="Your Details"
                                >
                                    <AccountRow>
                                        <PrimaryText>
                                            {authCtx.UserDetails.Person?.FirstName}
                                        </PrimaryText>

                                        <PinkTextButton onClick={() => { document.dispatchEvent(new CustomEvent('editPerson')) }} disabled={false}>
                                            Edit Name
                                        </PinkTextButton>
                                    </AccountRow>

                                    <AccountRow>
                                        <PrimaryText>
                                            {authCtx.UserDetails.Email}
                                        </PrimaryText>

                                        <PinkTextButton onClick={() => { document.dispatchEvent(new CustomEvent('editEmail')) }} disabled={false}>
                                            Edit Email
                                        </PinkTextButton>
                                    </AccountRow>

                                    <AccountRow>
                                        <PrimaryText>
                                            Password: **********
                                        </PrimaryText>

                                        <PinkTextButton onClick={() => { document.dispatchEvent(new CustomEvent('changePassword')) }} disabled={false}>
                                            Change Password
                                        </PinkTextButton>
                                    </AccountRow>
                                </AccountSection>

                                <AccountSection
                                    title="Email Preferenes"
                                >
                                    <EditCommercialEmails
                                        specialOffers={
                                            authCtx.UserDetails.AllowCommericalEmails !== undefined
                                                ? authCtx.UserDetails.AllowCommericalEmails
                                                : false
                                        }
                                    />

                                    <EditDailyNewsletter value={
                                        authCtx.UserDetails.AllowDailyNewsletter !== undefined
                                            ? authCtx.UserDetails.AllowDailyNewsletter
                                            : false}
                                    />
                                </AccountSection>

                                <AccountSection
                                    title={"Current Plan"}
                                    headingSibling={
                                        authCtx.UserDetails.AccessLevel >= 5 && !NeedsRenew(authCtx.UserDetails) ?
                                            null
                                            :
                                            (
                                                canUpgrade ?
                                                    (
                                                        <PinkButton
                                                            onClick={() => {
                                                                SetDisplayUpgrade(true);
                                                            }}
                                                        >
                                                            Upgrade
                                                        </PinkButton>
                                                    )
                                                    :
                                                    (
                                                        <NavLinkPinkButton
                                                            to={RoutePaths.ChoosePlan}
                                                        >
                                                            Choose Plan
                                                        </NavLinkPinkButton>
                                                    )
                                            )
                                    }
                                >
                                    <ContentContainer>
                                        <ContentSpit>
                                            <Heading type={HeadingType.H3}>
                                                {currentPlanTitle}
                                            </Heading>

                                            <PlanTable>
                                                <PlanElem>
                                                    <h4>Number of active devices:</h4>
                                                    <p>
                                                        {GetAccessLevelTitle(
                                                            authCtx.UserDetails.AccessLevel
                                                        )}
                                                    </p>
                                                </PlanElem>
                                                <PlanElem>
                                                    <h4>Auto renewal: </h4>
                                                    <p>
                                                        {GetRenewStatusTitle(
                                                            authCtx.UserDetails.SubscriptionStatus
                                                        )}
                                                    </p>
                                                </PlanElem>
                                                {
                                                    accountData !== undefined &&
                                                    accountData !== null &&
                                                    accountData.Subscription !== undefined &&
                                                    accountData.Subscription !== null ?
                                                    (<>
                                                        <PlanElem>
                                                            <h4>Start date: </h4>
                                                            <p>
                                                                {moment(new Date(accountData.Subscription.SubscriptionStartDate))
                                                                    .format("Do MMMM YYYY").toString()}
                                                            </p>
                                                        </PlanElem>
                                                        <PlanElem>
                                                            <h4>End date: </h4>
                                                            <p>
                                                                {moment(new Date(accountData.Subscription.SubscriptionEndDate))
                                                                    .format("Do MMMM YYYY").toString()}
                                                            </p>
                                                        </PlanElem>
                                                    </>)
                                                    : null}
                                                {
                                                    accountData !== undefined &&
                                                    accountData !== null &&
                                                    accountData.Subscription !== null &&
                                                    accountData.Subscription !== undefined ?
                                                    (<>
                                                        <PlanElem>
                                                            <h4>Billing amount: </h4>
                                                            <p><sup>{currencySymbol}</sup>
                                                                {accountData.Subscription?.BillingAmount}</p>
                                                        </PlanElem>
                                                        <PlanElem>
                                                            <h4>Billing frequency: </h4>
                                                            <p>
                                                                {PlanInterval(
                                                                    accountData.Subscription
                                                                        .BillingFrequency
                                                                )}
                                                            </p>
                                                        </PlanElem>
                                                    </>)
                                                    : null
                                                }
                                                <PlanElem>
                                                    <h4>Payment method: </h4>
                                                    <p>
                                                        {accountData !== undefined && accountData !== null
                                                            ? " " + accountData.Last4.toString()
                                                            : " n/a"}
                                                    </p>

                                                    <PinkTextButton
                                                        onClick={() => { document.dispatchEvent(new CustomEvent('updateBillingDetails')) }}
                                                        disabled={false}
                                                    >
                                                        Update Billing Details
                                                    </PinkTextButton>
                                                </PlanElem>
                                            </PlanTable>
                                            {
                                                accountData !== undefined &&
                                                accountData !== null ? (
                                                <>
                                                    <PrimaryText>
                                                        {accountData.Subscription !== null &&
                                                            accountData.Subscription !== undefined
                                                            ? GetPlanSubText(
                                                                authCtx.UserDetails.SubscriptionStatus,
                                                                new Date(
                                                                    accountData.Subscription.SubscriptionEndDate
                                                                ),
                                                                accountData.Subscription.BillingFrequency
                                                            )
                                                            : null}
                                                    </PrimaryText>

                                                    <PinkTextButton
                                                        onClick={() => { document.dispatchEvent(new CustomEvent('cancelAutoRenewal')) }}
                                                        disabled={disableCancel}
                                                    >
                                                        Cancel Auto Renewal
                                                    </PinkTextButton>
                                                </>
                                            ) : null}
                                            <br />
                                        </ContentSpit>
                                    </ContentContainer>
                                </AccountSection>

                                <AccountSection title={"Disconnect Devices"}>
                                    <ContentSpit>
                                        <PrimaryText>After clicking this button, every other device will need to log in again.</PrimaryText>
                                        <br />
                                        <GreyButton onClick={DisconnectOtherDevices}>Disconnect Devices</GreyButton>
                                    </ContentSpit>
                                </AccountSection>

                                <UpgradeModal
                                    display={displayUpgrade}
                                    setDisplay={SetDisplayUpgrade}
                                    setSuccess={SetUpgradeSuceess}
                                />

                                {!disableCancel && accountData.Subscription !== null ? (
                                    <CancelModal
                                        display={displayCancel}
                                        setDisplay={SetDisplayCancel}
                                        subscription={accountData.Subscription as ISubscriptionDTO}
                                    />
                                ) : null}
                            </Container>

                            <PopUp
                                canBeDismissed={true}
                                isFullScreen={true}
                                openEvent="editPerson"
                            >
                                <Card>
                                    <EditPerson />
                                </Card>
                            </PopUp>


                            <PopUp
                                canBeDismissed={true}
                                isFullScreen={true}
                                openEvent="editEmail"
                            >
                                <Card>
                                    <EditEmail />
                                </Card>
                            </PopUp>

                            <PopUp
                                canBeDismissed={true}
                                isFullScreen={true}
                                openEvent="changePassword"
                            >
                                <Card>
                                    <ChangePasswordForm />
                                </Card>
                            </PopUp>

                            {!displayUpgrade ? (
                                <PopUp
                                    canBeDismissed={true}
                                    isFullScreen={true}
                                    openEvent="updateBillingDetails"
                                >
                                    <StripeCardContainer>
                                        <StripeCard setPaymentMethod={AddStripePaymentMethod} />
                                    </StripeCardContainer>
                                </PopUp>
                            ) : undefined}
                        </>
                    );
                }}
            </Await>
        </Suspense>
    );
}

export default AccountScreen;

export async function Loader() {
    const controller = new AbortController();

    const userDetails = await UserSnapshot;

    if (userDetails === null || userDetails === undefined) {
        return redirect(RoutePaths.Root);
    }

    const responce = AccountAuth(userDetails);

    if (responce !== undefined) {
        return responce;
    }

    return defer({
        accountPromise: GetYourAccountDetails(userDetails, controller),
    });
}
