import { AxiosError } from "axios";
import { Suspense, useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Await, defer, useLoaderData } from "react-router-dom";
import styled from "styled-components";
import { GetFeaturedContent, GetFilms } from "../../../../Api/Carousel";
import CarouselItem from "../../../../Components/Carousels/CarouselItem";
import FeaturedCarousel from "../../../../Components/Carousels/FeaturedCarousel";
import ListContainer from "../../../../Components/UI/List/ListContainer";
import FeaturedSliderLoader from "../../../../Components/UI/PageLoaders/FeaturedSliderLoader";
import { AuthValidSubOnly } from "../../../../Helpers/RouteAuth";
import { SetTitle } from "../../../../Helpers/Utility";
import ICarouselContentDTO from "../../../../Models/DTOs/ICarouselContentDTO";
import { ContentType } from "../../../../Models/Enums/ContentType";
import AuthContext, { UserSnapshot } from "../../../../Store/auth-context";
import { ResponsiveBreakpoints } from "../../../../Constants/ResponsiveBreakpoints";
import VideoSingleLoader from "../../../../Components/UI/PageLoaders/VideoSingleLoader";

const
    Container = styled.section`
        padding: 1rem;
        width: 100%;
        max-width: calc(1616rem/16);
        margin: 0 auto;
        box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5);
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
            padding: 1.5rem;
        }
    `,
    take = 30;

function FilmsScreen() {
    const
        { featuredPromise, filmsPromise} = useLoaderData() as {
            featuredPromise: Promise<ICarouselContentDTO[]>;
            filmsPromise: Promise<ICarouselContentDTO[]>;
        },
        authCtx = useContext(AuthContext),
        [ films, setFilms ] = useState<ICarouselContentDTO[]>([]),
        [ hasMore, setHasMore ] = useState<boolean>(true),
        Films = async (skip: number) => {
            const result = await GetFilms(
                authCtx.UserDetails.CurrentCountryCode,
                authCtx.UserDetails.AspNetUserId,
                take,
                skip
            );

            if (result === null || result === undefined || result instanceof AxiosError || result.length <= 0) {
                setHasMore(false);
                return;
            }

            setFilms((prev) => [...prev, ...result]);
        };

    return (
        <>
            <Suspense fallback={ <FeaturedSliderLoader/> }>
                <Await resolve={ featuredPromise }>
                    { (featuredData) => {
                        const featured = featuredData as ICarouselContentDTO[];
                        return (
                            <FeaturedCarousel
                                content={ featured }
                                allowedContent={ [ContentType.Film] }
                            />
                        );
                    } }
                </Await>
            </Suspense>

            <Container>
                <Suspense fallback={
                    <ListContainer>
                        { [...Array(take - 5)].map((e, i) => (
                            <VideoSingleLoader paddingTop="8" key={ i } />
                        )) }
                    </ListContainer>
                }>
                    <Await resolve={ filmsPromise }>
                        { (filmsData) => {
                            const initFilms = filmsData as ICarouselContentDTO[];
                            const mergedLists = [...initFilms, ...films];

                            return (
                                <InfiniteScroll
                                    dataLength={ mergedLists.length }
                                    hasMore={ hasMore }
                                    next={ () => Films(mergedLists.length) }
                                    loader={
                                        <ListContainer>
                                            { [...Array(5)].map((e, i) => (
                                                <VideoSingleLoader paddingTop="8" key={ i } />
                                            )) }
                                        </ListContainer>
                                    }
                                >
                                    <ListContainer>
                                        { mergedLists.map((content, index) => {
                                            return (
                                                <CarouselItem
                                                    key={ index.toString() + content.Id }
                                                    Content={ content }
                                                    disableOverlay={ true }
                                                />
                                            );
                                        }) }
                                    </ListContainer>
                                </InfiniteScroll>
                            );
                        } }
                    </Await>
                </Suspense>
            </Container>
        </>
    );
}

export default FilmsScreen;

export async function Loader() {
    const
        controller = new AbortController(),
        userDetails = await UserSnapshot,
        InitFeatured =  async () => {
            const result = await GetFeaturedContent(
                userDetails.CurrentCountryCode,
                userDetails.AspNetUserId,
                controller
            );

            if (result === null || result instanceof AxiosError) {
                return [] as ICarouselContentDTO[];
            }

            return result;
        },
        InitFilms = async () => {

            const result = await GetFilms(
                userDetails.CurrentCountryCode,
                userDetails.AspNetUserId,
                take,
                0
            );

            if (result === null || result instanceof AxiosError) {
                return [] as ICarouselContentDTO[];
            }

            return result;
        },
        response = AuthValidSubOnly(userDetails);

    if (response !== undefined) {
        return response;
    }

    SetTitle("Films");

    return defer({
        featuredPromise: InitFeatured(),
        filmsPromise: InitFilms(),
    });
}
