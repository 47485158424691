import React from "react";
import {
    defer,
    Params,
    redirect,
    useLoaderData,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { SetTitle } from "../../Helpers/Utility";
import { GetVideo, GetVideoPeak } from "../../Api/Video";
import { IRatingDTO } from "../../Models/DTOs/IRatingDTO";
import { GetVideoRating } from "../../Api/Rating";
import { GetComments } from "../../Api/VideoComment";
import { RoutePaths } from "../../Constants/RoutePaths";
import { UserSnapshot } from "../../Store/auth-context";
import IVideoDTO from "../../Models/DTOs/IVideoDTO";
import ICommentAndPersonDTO from "../../Models/DTOs/ICommentAndPersonDTO";
import { IsActive } from "../../Helpers/UserUtility";
import FullWatch from "../../Components/UI/Watch/FullWatch";
import FreeWatch from "../../Components/UI/Watch/FreeWatch";
import { AxiosError } from "axios";

async function GetVideoComments(videoId: number, controller: AbortController): Promise<ICommentAndPersonDTO[]> {
    try {
        const result = await GetComments(videoId, controller);
        if (result instanceof AxiosError) {
            return [] as ICommentAndPersonDTO[];
        }
        return result ?? [] as ICommentAndPersonDTO[];
    } catch (error) {
        return [] as ICommentAndPersonDTO[];
    }
}

function WatchScreen() {
    const { videoPromise, ratingPromise, commentPromise, peakData, session, useFree } = useLoaderData() as {
        videoPromise: Promise<IVideoDTO>;
        ratingPromise: Promise<IRatingDTO>;
        commentPromise: Promise<ICommentAndPersonDTO[]>;
        peakData: number;
        session: string;
        useFree: boolean;
    };

    return useFree ? (
        <FreeWatch videoPromise={videoPromise} ratingPromise={ratingPromise} commentPromise={commentPromise} />
    ) : (
        <FullWatch videoPromise={videoPromise} ratingPromise={ratingPromise} commentPromise={commentPromise} peakData={peakData} session={session} />
    );
}

export default WatchScreen;

export async function Loader({ params }: { params: Readonly<Params<string>> }) {
    const controller = new AbortController();
    const userDetails = await UserSnapshot;
    const videoId = Number(params.id);

    if (isNaN(videoId) || videoId === 0) {
        throw new Response("Not a valid video id", { status: 400, statusText: "Bad request" });
    }

    const session = uuidv4();
    const isAuth = IsActive(userDetails);

    async function InitVideo(): Promise<IVideoDTO | Response | undefined> {
        try {
            const result = await GetVideo(videoId, userDetails.CurrentCountryCode, controller);
            if (result && 'Id' in result) {
                SetTitle(result.Title ?? "Video");
                return result;
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                throw error.response;
            }
        }
        controller.abort();
        return redirect(RoutePaths.Browse);
    }

    async function GetRating(videoId: number, userId: string | undefined): Promise<IRatingDTO | undefined> {
        try {
            const result = await GetVideoRating(videoId, userId, controller);
            if (!controller.signal.aborted && result && !(result instanceof AxiosError)) {
                return result;
            }
        } catch (error) {
            // Handle error if necessary
        }
    }

    async function GetPeak(): Promise<number> {
        if (!isAuth) return 0;
        try {
            const result = await GetVideoPeak(userDetails.AspNetUserId, videoId, controller);
            if (result instanceof AxiosError) {
                return 0;
            }
            return result?.Peak ?? 0;
        } catch (error) {
            return 0;
        }
    }

    return defer({
        videoPromise: InitVideo(),
        ratingPromise: GetRating(videoId, userDetails.AspNetUserId),
        commentPromise: GetVideoComments(videoId, controller),
        peakData: await GetPeak(),
        session,
        useFree: !isAuth,
    });
}
