import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { PlanType } from "./Models/Enums/PlanType";
import { TvPlatform } from "./Models/Enums/TvPlatforms";
import { RoutePaths } from './Constants/RoutePaths';
import { ContentType } from "./Models/Enums/ContentType";
import { PanelSelector } from "./Models/Enums/PanelSelector";
import "./App.css";

// Screens
import LandingScreen, { Loader as panelLoader } from "./Screens/LandingScreen";
import LoginScreen, { Loader as loginLoader } from "./Screens/Account/Authentication/LoginScreen";
import NewsDetailsScreen, { Loader as newsDetailsLoader } from "./Screens/Watch/NewsDetailsScreen";
import SeriesDetailsScreen, { Loader as seriesDetailLoader } from "./Screens/Watch/SeriesDetailsScreen";
import WatchScreen, { Loader as watchLoader } from "./Screens/Watch/WatchScreen";
import AccountScreen, { Loader as accountLoader } from "./Screens/Account/AccountScreen";
import ArticlesScreen from "./Screens/Read/ArticlesScreen";
import ArticleCategoryScreen from "./Screens/Read/ArticleCategoryScreen";
import ArticleDetailsScreen from "./Screens/Read/ArticleDetailsScreen";
import CreateYourAccountScreen, { Loader as createAccountLoader } from "./Screens/Account/Subscription/CreateYourAccountScreen";
import ChoosePlanScreen, { Loader as choosePlanLoader } from "./Screens/Account/Subscription/ChoosePlanScreen";
import ConfirmSubscriptionScreen, { Loader as confirmSubLoader } from "./Screens/Account/Subscription/ConfirmSubscriptionScreen";
import TrailerScreen, { Loader as trailerLoader } from "./Screens/Watch/TrailerScreen";
import HelpCentreScreen from './Screens/Company/HelpCentreScreen';
import TermsOfUseScreen from "./Screens/Company/TermsOfUseScreen";
import PrivacyPolicyScreen from "./Screens/Company/PrivacyPolicyScreen";
import RenewScreen, { Loader as renewLoader } from "./Screens/Account/Subscription/RenewScreen";
import ErrorScreen from "./Screens/ErrorScreen";
import RootLayout, { Loader as rootLoader } from "./Screens/RootLayout";
import Renew3DSConfirmScreen from "./Screens/Account/Subscription/Renew3DSConfirmScreen";
import CareerScreen from "./Screens/Company/CareerScreen";
import TvAuthenticationScreen from "./Screens/Account/Authentication/TvAuthenticationScreen";
import ForgotPasswordScreen, { Loader as forgotLoader } from "./Screens/Account/Password/ForgotPasswordScreen";
import ResetPasswordScreen, { Loader as resetLoader } from "./Screens/Account/Password/ResetPasswordScreen";
import AffiliateScreen, { Loader as affiliateLoader } from "./Screens/AffiliateScreen";
import CategoryScreen, { Loader as categoryLoader } from "./Screens/Watch/ContentListScreens/CategoryScreen";
import CategoryFilmsScreen, { Loader as categoryFilmsLoader } from "./Screens/Watch/ContentListScreens/CategoryFilmsScreen";
import AuthScreenRedirect, { Loader as AuthScreenLoader } from "./Components/AuthScreenRedirect";

// Landing Pages
import LifetimeMembership from "./Screens/LandingPages/LifetimeMembership";

// Subscribed Screens
import BrowseScreen from "./Screens/Watch/Subscribed/BrowseScreen";
import FilmsScreen from "./Screens/Watch/ContentListScreens/Subscribed/FilmsScreen";
import SeriesScreen from "./Screens/Watch/ContentListScreens/Subscribed/SeriesScreen";
import CategoryDetailsScreen from "./Screens/Watch/ContentListScreens/Subscribed/CategoryDetailsScreen";

// Free Screens
import BrowseScreenFree from "./Screens/Watch/Free/BrowseScreenFree";
import SeriesScreenFree from "./Screens/Watch/ContentListScreens/Free/SeriesScreenFree";
import FilmsScreenFree from "./Screens/Watch/ContentListScreens/Free/FilmsScreenFree";
import CategoryDetailsScreenFree from "./Screens/Watch/ContentListScreens/Free/CategoryDetailsScreenFree";
import { PopUpType } from "./Models/Enums/PopUpType";
import { BannerType } from "./Models/Enums/BannerType";

function App() {
    const router = createBrowserRouter([{
        path: RoutePaths.Root,
        id: "root",
        element: <RootLayout />,
        errorElement: <ErrorScreen />,
        loader: rootLoader,
        children: [
            // Unauthenticated pages
            { index: true, element: <LandingScreen />, loader: () => panelLoader(PanelSelector.Generic) },
            { path: "Login/:redirectTo?", element: <LoginScreen />, loader: loginLoader }, // Optional parameter, do not change to RoutePaths
            { path: RoutePaths.ForgotPassword, element: <ForgotPasswordScreen />, loader: forgotLoader },
            { path: RoutePaths.ResetPassword(":userId", ":code"), element: <ResetPasswordScreen />, loader: resetLoader },
            { path: RoutePaths.CreateYourAccount, element: <CreateYourAccountScreen />, loader: () => createAccountLoader({}) },
            { path: RoutePaths.Affiliate(":link"), element: <AffiliateScreen />, loader: affiliateLoader },

            // Create your account
            { path: RoutePaths.CreateYourAccountGameChanger, element: <CreateYourAccountScreen />, loader: () => createAccountLoader({ selectedPlan: PlanType.GameChanger }) },
            { path: RoutePaths.CreateYourAccountTruthSeeker, element: <CreateYourAccountScreen />, loader: () => createAccountLoader({ selectedPlan: PlanType.TruthSeeker }) },

            // Marketing Landing Pages
            { path: RoutePaths.LifetimeMembership, element: <LifetimeMembership /> },

            // Authenticated pages
            { path: RoutePaths.Account, element: <AccountScreen />, loader: accountLoader },
            { path: RoutePaths.ChoosePlan, element: <ChoosePlanScreen />, loader: choosePlanLoader },
            { path: RoutePaths.ConfirmSubscription, element: <ConfirmSubscriptionScreen />, loader: confirmSubLoader },
            { path: RoutePaths.Renew, element: <RenewScreen />, loader: renewLoader },
            { path: RoutePaths.Browse, element: <AuthScreenRedirect FreeScreen={<BrowseScreenFree />} SubscribedScreen={<BrowseScreen />} />, loader: () => AuthScreenLoader(PanelSelector.Browse) },
            { path: RoutePaths.Watch(":id"), element: <WatchScreen />, loader: watchLoader },
            { path: RoutePaths.Series, element: <AuthScreenRedirect FreeScreen={<SeriesScreenFree />} SubscribedScreen={<SeriesScreen />} />, loader: () => AuthScreenLoader(PanelSelector.Series) },
            { path: RoutePaths.Films, element: <AuthScreenRedirect FreeScreen={<FilmsScreenFree />} SubscribedScreen={<FilmsScreen />} />, loader: () => AuthScreenLoader(PanelSelector.Films) },
            { path: RoutePaths.Category(':id'), element: <CategoryScreen />, loader: (data) => categoryLoader({ params: data.params, category: undefined }) },

            // Both authenticated and unauthenticated
            { path: RoutePaths.Renew3DSConfirm, element: <Renew3DSConfirmScreen /> },
            { path: RoutePaths.BrowseArticles, element: <ArticlesScreen /> },
            { path: RoutePaths.ArticleCategory(":id"), element: <ArticleCategoryScreen /> },
            { path: RoutePaths.ReadArticle(":id"), element: <ArticleDetailsScreen /> },
            { path: RoutePaths.ReadArticleOld(":id"), element: <ArticleDetailsScreen /> },
            { path: RoutePaths.SeriesTrailer(":id"), element: <TrailerScreen />, loader: (data) => trailerLoader({ params: data.params, contentType: ContentType.Series }) },
            { path: RoutePaths.WatchTrailer(":id"), element: <TrailerScreen />, loader: (data) => trailerLoader({ params: data.params, contentType: ContentType.Film }) },
            { path: RoutePaths.SeriesEpisodes(":id"), element: <SeriesDetailsScreen />, loader: seriesDetailLoader },
            { path: RoutePaths.News, element: <AuthScreenRedirect FreeScreen={<LandingScreen />} SubscribedScreen={<CategoryDetailsScreen />} />, loader: () => AuthScreenLoader(PanelSelector.NewsAndCurrentAffairs) },
            { path: RoutePaths.WeeklyShows, element: <AuthScreenRedirect FreeScreen={<CategoryDetailsScreenFree popUpType={PopUpType.WeeklyShows} bannerType={BannerType.WeeklyShows} />} SubscribedScreen={<CategoryDetailsScreen popUpType={PopUpType.WeeklyShows} bannerType={BannerType.WeeklyShows} />} />, loader: () => AuthScreenLoader(PanelSelector.NewsAndCurrentAffairs) },
            { path: RoutePaths.NewsDetails(":id"), element: <NewsDetailsScreen />, loader: newsDetailsLoader },
            { path: RoutePaths.HelpCentre, element: <HelpCentreScreen /> },
            { path: RoutePaths.TermsOfUse, element: <TermsOfUseScreen /> },
            { path: RoutePaths.PrivacyPolicy, element: <PrivacyPolicyScreen /> },
            { path: RoutePaths.Careers, element: <CareerScreen /> },
            { path: RoutePaths.CategoryFilms(":id"), element: <CategoryFilmsScreen />, loader: (data) => categoryFilmsLoader({ params: data.params, category: undefined, backButtonRoutePath: RoutePaths.Category(Number(data.params.id)) }) },

            // TV Auth
            { path: RoutePaths.GenericTvAuth, element: <TvAuthenticationScreen platform={TvPlatform.Generic} /> },
            { path: RoutePaths.Roku, element: <TvAuthenticationScreen platform={TvPlatform.Roku} /> },
            { path: RoutePaths.AndroidTV, element: <TvAuthenticationScreen platform={TvPlatform.AndroidTv} /> },
            { path: RoutePaths.SamsungTV, element: <TvAuthenticationScreen platform={TvPlatform.SamsungTv} /> },
            { path: RoutePaths.Firestick, element: <TvAuthenticationScreen platform={TvPlatform.FireStick} /> },

            // Additional Routes redirecting to specific pages
            { path: RoutePaths.Wtaf, element: <Navigate to={RoutePaths.SeriesEpisodes(107)} /> },
            { path: RoutePaths.DotConnector, element: <Navigate to={RoutePaths.SeriesEpisodes(121)} /> },
            { path: RoutePaths.TheDotConnector, element: <Navigate to={RoutePaths.SeriesEpisodes(121)} /> },
            { path: RoutePaths.RightNow, element: <Navigate to={RoutePaths.SeriesEpisodes(111)} /> },
            { path: RoutePaths.IsNowTheTime, element: <Navigate to={RoutePaths.SeriesEpisodes(128)} /> },
            { path: RoutePaths.DavidIcke, element: <AuthScreenRedirect FreeScreen={<CategoryDetailsScreenFree popUpType={PopUpType.DavidIcke} bannerType={BannerType.DavidIcke} />} SubscribedScreen={<CategoryDetailsScreen popUpType={PopUpType.DavidIcke} bannerType={BannerType.DavidIcke} />} />, loader: () => AuthScreenLoader(PanelSelector.DavidIcke) },
            { path: RoutePaths.DavidIckeFilms, element: <CategoryFilmsScreen />, loader: () => categoryFilmsLoader({ category: 17, backButtonRoutePath: RoutePaths.DavidIcke }) }
        ]
    }]);

    return (<RouterProvider router={router} />);
}

export default App;
