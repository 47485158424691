import {Suspense, useContext, useEffect, useState} from "react";
import {Await, useNavigate} from "react-router-dom";
import {IRatingDTO} from "../../../Models/DTOs/IRatingDTO";
import {RatingButtonType} from "../../../Models/Enums/RatingButtonType";
import {Colours} from "../../../Constants/Colours";
import {GetComments} from "../../../Api/VideoComment";
import styled from "styled-components";
import moment from "moment";
import AuthContext from "../../../Store/auth-context";
import IVideoDTO from "../../../Models/DTOs/IVideoDTO";
import ICommentAndPersonDTO from "../../../Models/DTOs/ICommentAndPersonDTO";
import Heading from "../Text/Heading";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
import Socials from "../Socials";
import LikeDislikeButton from "../Buttons/LikeDislikeButton";
import PrimaryText from "../Text/PrimaryText";
import VideoComments from "../../VideoComments";
import SocialAndRatingsLoader from "../PageLoaders/SocialAndRatingsLoader";
import WatchPageLoader from "../PageLoaders/WatchPageLoader";
import {RoutePaths} from "../../../Constants/RoutePaths";
import {AxiosError} from "axios";
import {ContentType} from "../../../Models/Enums/ContentType";
import PopUp from "../Modals/PopUp";
import NavLinkPinkButtonFat from "../Buttons/NavLinkPinkButtonFat";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";
import EmailFreeView from "../Forms/EmailFreeView";
import {HeadingType} from "../../../Models/Enums/HeadingType";
import {PopUpType} from "../../../Models/Enums/PopUpType";
import NavLinkGreyButton from "../Buttons/NavLinkGreyButton";
import SharingMetatags from "../../Headers/SharingMetatags";

const
    Container = styled.section`
        max-width: 1632px;
        margin: 0 auto;
        width: 100%;
    `,
    VideoContainerInner = styled.div`
        max-width: calc(1600rem/16);
        position: relative;
        flex-basis: 100%;
        width: 100%;
        height: 100%;
        background: #000;
        margin: 0 auto;
        max-height: calc(100vh - calc(170rem/16));
        .popup__hard-close {
            @media screen and (min-width: calc(${ ResponsiveBreakpoints.MediumMobileBreakpoint }rem/16)) {
                background: rgba(0,0,0,0.6);
                border-radius: calc(10rem/16);
                max-width: calc(450rem/16);
                padding: 2rem;
                animation-name: pulseSoft;
                animation-duration: 3.5s;
                animation-iteration-count: infinite;
                h3 {
                    max-width: calc(300rem/16);
                    margin: 0 0 2rem 0;
                }
            }
            
            @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }rem/16)) {
                padding: 3rem;
            }
        }
    `,
    VideoContainer = styled.div`
        height: 56.25vw;
        max-height: calc(100vh - calc(170rem/16));
        @media screen and (min-width: calc(1600rem/16)) and (min-height: calc(900rem/16)) {
            height: calc(900rem/16);
        }
    `,
    CTAContainer = styled.div`
        padding: 1.5rem 1rem;
        max-width: 1600px;
        margin: 0 auto;
        background: #0d0d0d;
        p {
            margin: 0;
            text-align: center;
            line-height: 1.4;
            color: ${ Colours.Text };
            font-size: 12pt;
            a {
                margin: 1rem auto 0 auto;
                padding: 9px 30px;
                display: table;
                @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
                    margin: 0 0 0 20px;
                    display: inline-block;
                   
                }
            }
        }
    `,
    ContentContainer = styled.div`
        height: auto;
        padding: 1rem;    
        > p {
            margin: 0 0 1rem 0;
            max-width: 1000px;
            &:first-of-type {
                color: ${ Colours.TertiaryHighlight };
                margin-bottom: 8px;
            }
        }
    `,
    TitleSocialsRatings = styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 0 1rem 0;
        > h1 {
            width: 100%;
            margin: 0 0 calc(12rem/16) 0;
            font-size: 16pt;
            @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
                font-size: 18pt;
            }
        }
        
        > div {
            overflow-x: scroll;
            width: 100%;
            > div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-width: 435px;
            }
            
            /* Webkit */
            ::-webkit-scrollbar {
                width: 3px;
                height: 3px;
            }
                    
            ::-webkit-scrollbar-track {
                background: #222;
            }
                    
            ::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 4px;
            }
                    
            ::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
                    
            /* Firefox */
            scrollbar-width: thin;
            scrollbar-color: #888 #222;
        }
        
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
            > div {
                > div {
                    min-width: 468px;
                }
            }
        }
        
        
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
            > h1 {
                margin: 0 0 calc(12rem/16) 0;
                width: calc(100% - (545rem/16));
            }
            
            > div {
                margin: calc(-16rem/16) 0 0 0;
                width: 528px;
                > div {
                    width: 528px;
                }
            }
        }
    `,
    RatingsContainer = styled.div`
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 0 1rem 0 0;
        padding: 0 10px;
        height: 51px;
        border-radius: 25px;
        background: #050505;
    `,
    CenteredCTAContainer = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        a {
            padding: 0;
            font-size: 12pt;
            color: ${ Colours.IckonicPink };
            box-shadow: inset 0 0 0 calc(0rem/16) ${ Colours.IckonicPink };
            @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallMobileBreakpoint }em/16)) {
                padding: 10px 30px;
                color: white;
                box-shadow: inset 0 0 0 calc(2rem/16) ${ Colours.IckonicPink };
            }
            
            @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
                padding: 20px 30px;
                font-size: 14pt;
            }
        }
        
        h3 {
            text-align: center;
            margin: 0 0 8px 0;
            font-size: 12pt;
            @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallMobileBreakpoint }em/16)) {
                margin: 0 0 1rem 0;
                font-size: 14pt;
            }
            
            @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
                font-size: 20pt;
            }
        }
    `;

// Is called in Loader and in component to update comments
async function GetVideoComments(videoId: number, controller: AbortController) {
    const result = await GetComments(videoId, controller);

    if (result === null || result === undefined || result instanceof AxiosError) {
        return [] as ICommentAndPersonDTO[];
    }

    return result;
}

function FreeWatch(props: {
    videoPromise: Promise<IVideoDTO>;
    ratingPromise: Promise<IRatingDTO>;
    commentPromise: Promise<ICommentAndPersonDTO[]>;
}) {

    const
        authCtx = useContext(AuthContext),
        controller = new AbortController(),
        [ commentLoader, setCommentLoader ] = useState<Promise<ICommentAndPersonDTO[]>>(props.commentPromise),
        navigate = useNavigate(),
        UpdateComment =  async (videoId: number) => {
            const promise = GetVideoComments(videoId, controller);
            setCommentLoader(promise);
        };

    useEffect(() => {
        setCommentLoader(props.commentPromise)
    }, [ props.commentPromise ]);

    useEffect(() => {
        async function LoadData() {
            const videoData = await props.videoPromise;
            const video = videoData as IVideoDTO;

            if (!video || !video.Link) {
                navigate("/");
            }

            // Video is not free &&
            // User logged in with no sub
            if (!video.IsFreeView && authCtx.UserDetails.AspNetUserId !== undefined && authCtx.UserDetails.AspNetUserId !== null) {
                navigate(RoutePaths.ChoosePlan);
            }
        }

        LoadData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Suspense fallback={ <WatchPageLoader /> }>
            <PopUp
                canBeDismissed={ true }
                isFullScreen={ true }
                openEvent="onPauseEvent"
                apiEnum={ PopUpType.Watch }
            />

            <Await resolve={ props.videoPromise }>
                { (videoData) => {
                    const video = videoData as IVideoDTO;
                    return video && video.Link ? (
                        <Container data-testid="freeWatch">
                            {/*<SharingMetatags*/}
                            {/*    title={video.Title}*/}
                            {/*    description={video.Description}*/}
                            {/*    url={window.location.href.replace("http:", "https:")}*/}
                            {/*    image={video.Thumbnail}*/}
                            {/*    type="video"*/}
                            {/*/>*/}

                            <VideoContainer>
                                <VideoContainerInner>
                                    <PopUp
                                        canBeDismissed={ false }
                                        isFullScreen={ false }
                                    >
                                        { video.IsFreeView ?
                                            <EmailFreeView />
                                            :
                                            <div className="popup__hard-close">
                                                <CenteredCTAContainer>
                                                    <Heading type={ HeadingType.H3 }>
                                                        Unlock your mind and unleash your potential
                                                    </Heading>

                                                    <NavLinkPinkButtonFat to={ RoutePaths.CreateYourAccount }>
                                                        Start for just £1.99
                                                    </NavLinkPinkButtonFat>
                                                </CenteredCTAContainer>
                                            </div>
                                        }
                                    </PopUp>

                                    <VideoPlayer
                                        poster={ video.Thumbnail }
                                        src={ video.Link }

                                        // Disable playback if video is not free
                                        disablePlayback={ !video.IsFreeView }
                                    />
                                </VideoContainerInner>
                            </VideoContainer>

                            { video && (video.TrailerLink !== null || video.IsFreeView) && (
                                <CTAContainer>
                                    { video.IsFreeView ?
                                        (
                                            <p>
                                                Ready for More? Get your first month for just £1.99.
                                                <NavLinkPinkButtonFat to={ RoutePaths.CreateYourAccount }>
                                                    Sign Up Now
                                                </NavLinkPinkButtonFat>
                                            </p>
                                        )
                                        :
                                        video.TrailerLink !== null ?
                                            (
                                                <p style={ { fontSize: '16pt' } }>
                                                    Get a sneak peek:
                                                    <NavLinkGreyButton  to={ RoutePaths.WatchTrailer(video.Id) }>
                                                        Watch Trailer
                                                    </NavLinkGreyButton>
                                                </p>
                                            )
                                            :
                                            null
                                    }
                                </CTAContainer>
                            ) }

                            <ContentContainer>

                                { /** Release Date | Series / Episode Info **/ }
                                <PrimaryText>
                                    { moment(video.Datetime).format("Do MMMM YYYY").toString() } { video.SeriesId !== undefined && video.SeriesId > 0 ? "• Series " + video.Season + " - Episode " + video.Episode : null }
                                </PrimaryText>

                                <TitleSocialsRatings>

                                    { /** Video Title **/ }
                                    <Heading testId="videoTitle">{ video.Title }</Heading>

                                    <div>
                                        <div>
                                            <Suspense fallback={<SocialAndRatingsLoader />}>
                                                <Await resolve={props.ratingPromise}>
                                                    {(ratingData) => {
                                                        const loadedRating = ratingData as IRatingDTO;
                                                        return (
                                                            <RatingsContainer>
                                                                <LikeDislikeButton
                                                                    videoId={ video.Id }
                                                                    IconSize={ 25 }
                                                                    Rating={ loadedRating }
                                                                    aspNetUserId={
                                                                        authCtx.UserDetails.AspNetUserId
                                                                    }
                                                                    SetRatigActive={ () => { } }
                                                                    Type={ RatingButtonType.Like }
                                                                    isDisabled={ true }
                                                                    testId="likeBtn"
                                                                >
                                                                    <PrimaryText>
                                                                        { loadedRating.LikeCount }
                                                                    </PrimaryText>
                                                                </LikeDislikeButton>

                                                                <LikeDislikeButton
                                                                    videoId={ video.Id }
                                                                    IconSize={ 25 }
                                                                    Rating={ loadedRating }
                                                                    aspNetUserId={
                                                                        authCtx.UserDetails.AspNetUserId
                                                                    }
                                                                    SetRatigActive={ () => { } }
                                                                    Type={ RatingButtonType.Dislike }
                                                                    isDisabled={ true }
                                                                    testId="dislikeBtn"
                                                                >
                                                                    <PrimaryText>
                                                                        { loadedRating.DislikeCount }
                                                                    </PrimaryText>
                                                                </LikeDislikeButton>
                                                            </RatingsContainer>
                                                        );
                                                    }}
                                                </Await>
                                            </Suspense>

                                            <Socials
                                                displayText={ true }
                                                title={ video.Title }
                                                contentType={ video.SeriesId == null || video.SeriesId === undefined ? ContentType.Film : ContentType.Episode }
                                            />
                                        </div>
                                    </div>
                                </TitleSocialsRatings>

                                { /** Description **/ }
                                <PrimaryText>{ video.Description }</PrimaryText>

                                <Suspense fallback={
                                    <Heading>...Loading comments</Heading>
                                }>
                                    <Await resolve={commentLoader}>
                                        { (commentData) => {
                                            let comments = commentData as ICommentAndPersonDTO[];

                                            return (
                                                <VideoComments
                                                    videoId={ video.Id }
                                                    comments={ comments }
                                                    UpdateComments={ UpdateComment }
                                                    disable={ true}
                                                    disableText="Sign up to write a comment!"
                                                />
                                            );
                                        } }
                                    </Await>
                                </Suspense>
                            </ContentContainer>
                        </Container>
                    ) : null;
                } }
            </Await>
        </Suspense>
    );
}

export default FreeWatch;