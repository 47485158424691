import axios, { AxiosError } from "axios";
import { IPerson } from "../Models/IPerson";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.person;

export async function AddOrEditPerson(person: IPerson, access_token: string, abortController: AbortController) {

    const responce = await axios.post(BACKEND_URL, person, {
        signal: abortController.signal,
        headers: {
            Authorization: "Bearer " + access_token
        }
    }
    ).then((responce) => {
        return responce.data as boolean;
    }).catch((error: AxiosError) => {
        console.log("AddOrEditPerson error");

        return error;
    });

    return responce;
};